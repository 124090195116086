$(document).ready(function () {

	jQuery.each(jQuery('[data-role="slick-slider"]'), function () {
		var $slider = $(this),
			var_width = $slider.data('variable-width') || false,
			content_type = $slider.data('type') || 'normal',
			autoplay = $slider.data('auto') || false,
			autospeed = $slider.data('speed') || '10000';

		if (content_type == 'normal') {
			$slider.slick({
				variableWidth: var_width,
				centerMode: true,
				slidesToShow: 1,
				dots: false,
				arrows: true,
				infinite: true,
				draggable: true,
				autoplay: autoplay,
				autoplaySpeed: autospeed,
				appendDots: $('#slick-container'),

				prevArrow: '<button type="button" class="slick-prev">Prev</button>',
				nextArrow: '<button type="button" class="slick-next">Next</button>',

				responsive: [{
						breakpoint: 768,
						settings: {
							centerMode: true,
							centerPadding: '20px',
							slidesToShow: 1,
							variableWidth: false,
							arrows: false
						}
					},
					{
						breakpoint: 480,
						settings: {
							centerMode: true,
							centerPadding: '20px',
							slidesToShow: 1,
							variableWidth: false,
							arrows: false
						}
					}
				]
			});
		} else if (content_type == 'mini') {
			$slider.slick({
				variableWidth: var_width,
				centerMode: true,
				slidesToShow: 1,
				dots: false,
				arrows: false,
				infinite: true,
				draggable: true,
				responsive: [{
						breakpoint: 768,
						settings: {
							centerMode: true,
							centerPadding: '60px',
							slidesToShow: 1,
							variableWidth: false
						}
					},
					{
						breakpoint: 350,
						settings: {
							centerMode: true,
							centerPadding: '20px',
							slidesToShow: 1,
							variableWidth: false
						}
					}
				]
			});
		} else if (content_type == 'footer-carousel') {
			$slider.slick({
				variableWidth: var_width,
				centerMode: true,
				slidesToShow: 1,
				dots: false,
				arrows: false,
				infinite: true,
				draggable: true,
				autoplay: autoplay,
				autoplaySpeed: autospeed,
				responsive: [{
					breakpoint: 768,
					settings: {
						centerMode: true,
						centerPadding: '10px',
						slidesToShow: 3,
						variableWidth: false
					}
				}]
			});
		} else if (content_type == 'partners') {
			$slider
				.on('afterChange init', function (event, slick, direction) {
					// console.log('afterChange/init', event, slick, slick.$slides);
					// remove all prev/next
					$(slick.$slides).removeClass('prevSlide');
					$(slick.$slides).removeClass('nextSlide');

					// find current slide
					for (var i = 0; i < slick.$slides.length; i++) {
						var $slide = $(slick.$slides[i]);
						if ($slide.hasClass('slick-current')) {
							// update DOM siblings
							$slide.prev().addClass('prevSlide');
							$slide.next().addClass('nextSlide');
							break;
						}
					}

					setTimeout(function(){
						$(slick.$slides).removeClass('currentSlide');
						$('.slick-current').addClass('currentSlide');
				  	},75);
				})
				.on('beforeChange init', function (event, slick) {
					// optional, but cleaner maybe
					// remove all prev/next
					$(slick.$slides).removeClass('prevSlide');
					$(slick.$slides).removeClass('nextSlide');
				}).
				slick({
					variableWidth: var_width,
					centerMode: true,
					slidesToShow: 1,
					dots: false,
					arrows: true,
					infinite: true,
					draggable: true,
					speed: 150,
					appendArrows: $('[data-role="arrows-group"]'),
					prevArrow: '<button type="button" class="slick-prev">Prev</button>',
					nextArrow: '<button type="button" class="slick-next">Next</button>',
					responsive: [{
							breakpoint: 768,
							settings: {
								centerMode: true,
								centerPadding: '60px',
								slidesToShow: 1,
								infinite: true,
							}
						},
						{
							breakpoint: 350,
							settings: {
								centerMode: true,
								centerPadding: '16px',
								slidesToShow: 1,
								infinite: true,
							}
						}
					],
				});
		}
	});
});
